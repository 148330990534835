.footer {
    display: flex;
    gap: 0rem;
    padding: 0rem;
    background-color: #5b582f;
    justify-content: center;
    box-shadow: 0px -5px 10px rgba(0, 0, 0, 0.4);
  }

  .left-fo{
    
    flex:1.5;
    display:flex;
    gap:1.4rem;
    flex-direction: row;
} 

.right-fo{
    flex:1;
    position: relative;
    
}

.left-fo>img{
    padding: 3rem;
    padding-left: 23rem;
    padding-right: 0rem;
    padding-top: 2.5rem;
    width: 5rem;
}

.left-fo>p{
    padding-right: 0rem;
    padding-top: 3.6rem;
    padding-bottom: 3rem;
    color: var(--footerText);
}

.icon{
    color: var(--footerText);
    width: 2.7rem;
    font-size: 29px;
    padding-top: 4.25rem;
}

@media screen and (max-width :768px){
    .left-fo{
        gap: .5rem;
        flex: 1.3;
    }
    .left-fo>img{
        padding: 1rem;
        padding-left: 5rem;
        padding-right: 0rem;
        padding-top: 1rem;
        width: 3rem;
        height: 3rem;
    }
    .left-fo>p{
        padding-left: 0rem;
        padding-top: 1.7rem;
        padding-bottom: 0rem;
        color: var(--footerText);
        font-size: xx-small;
    }
    .icon{
        color: var(--footerText);
        width: 1.9rem;
        font-size: 20px;
        padding-top: 1.9rem;
    }
}