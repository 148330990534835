.slider {
    position: relative;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 8.5rem;
    padding-top: 6rem;
   
  }
  
  .image {
    width: 800px;
    height: 560px;
    border-radius: 10px;
    
  }
  
  .right-arrow {
    position: absolute;
    top: 50%;
    right: 32px;
    font-size: 3rem;
    color: #000;
    z-index: 10;
    cursor: pointer;
    user-select: none;
  }
  
  .left-arrow {
    position: absolute;
    top: 50%;
    left: 32px;
    font-size: 3rem;
    color: #000;
    z-index: 10;
    cursor: pointer;
    user-select: none;
  }
  
  .slide {
    opacity: 0;
    transition-duration: 1s ease;
  }
  
  .slide.active {
    opacity: 1;
    transition-duration: 1.5s;
    transform: scale(1.08);
  }

  .slide-header{
    display: flex;
    gap : 4.28rem;
    font-weight: bold;
    font-size: 2.8rem;
    justify-content: center;
    color: var(--green);
    text-transform: uppercase;
    font-style: italic;
    margin-bottom: 80px;
}

/* Add these styles to your CSS file */
.custom-left-arrow {
    position: absolute;
    top: 50%;
    left: 32px;
    font-size: 3rem;
    color: var(--green); /* Change the color as needed */
    z-index: 10;
    cursor: pointer;
    user-select: none;
  }
  
  .custom-right-arrow {
    position: absolute;
    top: 50%;
    right: 32px;
    font-size: 3rem;
    color: var(--green); /* Change the color as needed */
    z-index: 10;
    cursor: pointer;
    user-select: none;
  }
  
  /* Add these styles to your existing CSS file */
  .overlay {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 40%; /* You can adjust the width as needed */
    height:  10%; /* You can adjust the height as needed */
    background-color: rgba(229, 220, 221, .94); /* Adjust the RGB color values and alpha (0.7 for 70% opacity) */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    opacity: 0;
    transition: opacity 0.5s ease;
    text-align: center;
    color: var(--green);
    padding: 20px;
    box-sizing: border-box;
    border-radius: 10px;
    font-size: 1rem; /* Adjust the font size as needed */
    overflow: hidden; /* Hide any overflowing content */
  }
  

.overlay.active {
  opacity: 1;
}


/* Styles for arrow buttons on screens 768px or narrower */


@media screen and (max-width :768px){
   
   .slider{
    padding-top: 0rem;
    padding-bottom: 3rem;
    margin-top: 0rem;
    margin-bottom: 0rem;
    height: 500px;
   }
  .slide-header{
    flex-direction: column;
    gap: 0.5rem;
    font-size: xx-large;
    align-items: center;
    text-align: center;
    justify-content: center;    
}
.slide-header>span:nth-child(2){
  letter-spacing: 2px;
}
.custom-right-arrow,
  .custom-left-arrow {
    display: none; /* Hide the arrow buttons */
  }

.image {
  width: 330px;
  height: 220px;
  border-radius: 10px; /* Apply border-radius to the image */
  /* Remove padding-top as it's not needed */
}

.overlay {
  /* Adjust positioning proportionally to the image */
  width: 60%; /* Adjust the width as needed */
  height: 20%; /* Adjust the height as needed */
  left: 50%; /* Center the overlay horizontally */
  top: 50%; /* Center the overlay vertically */
  transform: translate(-50%, -50%); /* Center the overlay precisely */
  padding: 10px; /* Adjust the padding as needed */
  font-size: 0.7rem; /* Adjust the font size as needed */
  text-align: center; /* Center text within the overlay */
  border-radius: 10px; /* Apply border-radius to the overlay */
}



}
