.Programs {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  padding: 0 2rem;
  padding-top: 6rem;
}
.programs-header {
  display: flex;
  gap: 4.28rem;
  font-weight: bold;
  font-size: 2.8rem;
  justify-content: center;
  color: var(--green);
  text-transform: uppercase;
  font-style: italic;
}
.program-categories {
  margin-top: 3rem;
  display: flex;
  gap: 150px;
  justify-content: center;
}

.category {
  display: flex;
  flex-direction: column;
  background-color: var(--green);
  gap: 0.5rem;
  color: white;
  justify-content: space-between;
  width: 23%;
  height: 350px;
  border-radius: 10px;
  padding-bottom: 2rem;
}

.category > .image-container {
  width: 100%;
  height: 160px;
  margin-bottom: 0.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  border-radius: 10px 10px 0 0;
}

.category > .image-container img {
  width: 100%;
  height: 100%;
  object-fit: cover; /* Make the image cover the container without stretching */
}

.category > :nth-child(2),
.category > :nth-child(3) {
  padding-right: 2rem;
  padding-left: 2rem;
}
.category > :nth-child(2) {
  font-size: 1.5rem;
  font-weight: bold;
  margin-top: 10px;
}

.program-categories:first-child {
  margin-bottom: 20px; /* Add margin to the bottom of the first div */
}

.program-categories:last-child {
  margin-top: 20px; /* Add margin to the top of the second div */
}

.category > .image-container img {
  transform: scale(1);
  transition: transform 0.3s ease; /* Apply the transition to both hover and non-hover states */
}

.category:hover > .image-container img {
  transform: scale(1.1); /* Apply the scale when hovering */
}

@media screen and (max-width: 768px) {
  .Programs {
    gap: 0rem;
    padding: 0rem;
  }
  .programs-header {

    flex-direction: column;
    gap: 0.1rem;
    font-size: 30px;
    align-items: center;
    text-align: center;
    justify-content: center;
    letter-spacing: 1.6px;
    
  }
  .programs-header>span:nth-child(2){
    
    padding: 0.6rem;
    padding-top: 0.2rem;
  }
  .program-categories {
    flex-direction: column;
    gap: 3rem;
  }
  .category {
    
    width: 77%;
    align-self: center;
    height: 270px;
  }
  .category > .image-container {
    height: 100px;
    overflow: visible;
  }
  .category > .image-container > img {
    border-radius: 10px 10px 0px 0px;
    
    overflow: hidden;
  }
  .category:hover > .image-container img {
    transform: none;
}
  .category > :nth-child(2) {
    font-size: 1.2rem;
    text-align: center;
    padding: 0rem;
    margin: 0px;
  }
  .category > :nth-child(3) {
    text-align: center;
    font-size: 0.9rem;
    ;
  }
  

  .program-categories:last-child {
    margin-top: 3rem; /* Add margin to the top of the second div */
  }
}
