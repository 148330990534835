.contact{
    display: flex;
    justify-content: space-between;
}

/* .logo{
    width: 26rem;
    height: 6rem;  
} */
 .contact-menu{
    list-style: none;
    display: flex;
    gap: 2rem;
    color :#d8cacb;
 }

 .contact-menu > li:hover {
    color: whitesmoke; /* or use your var(white) if defined correctly */
    cursor: pointer;
  }

  .contact-menu>li>img{
    width: 20px;
  }
  @media screen and (max-width :768px){
    
  }
