
:root {

  --green:  #b3ad6a;
  --cream: #E5DCDD;
  --footerText: #c4a844;

}

.App {
  background-color: var(--cream);
  display: flex;
  flex-direction: column;
   
  overflow: hidden;
}
::-webkit-scrollbar{
  display: none;
}

.stroke-text{
  color:transparent;
  font-family: Arial, "Helvetica Neue", Helvetica, sans-serif;
  -webkit-text-stroke-width: 1.4px;
  -webkit-text-stroke-color:var(--green)

}

.stroke-text1{
  color:transparent;
  letter-spacing: 1px;
  font-family: Arial, "Helvetica Neue", Helvetica, sans-serif;
  -webkit-text-stroke-width: 1.4px;
  -webkit-text-stroke-color:var(--cream)

}

.btn{
  background-color: #d8cacb;
  padding: 0.5rem;
  font-weight: bold;
  border: 4px solid transparent;
  transition: 300ms all;
  display: flex;
  align-items: center;
  justify-content: center;
}

.btn:hover{
  cursor: pointer;
}

.form{
  background-color: var(--green);
}

